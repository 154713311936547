import {
  TextField,
  ReferenceField,
  DateField,
  List,
  Datagrid,
  ReferenceInput,
  SelectInput,
  FunctionField,
} from "react-admin";

export const RequestList = (props : any) => {
  const filters = [
    <ReferenceInput source="book"
      reference="bookss"
      label="Book"
      >
      <SelectInput optionText="title"/>
    </ReferenceInput>,
    <ReferenceInput source="user"
                    reference="users"
                    label="User"
    >
      <SelectInput optionText="name"/>
    </ReferenceInput>
  ];

  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <ReferenceField
          source="userBook"
          reference="user_books"
          label="Requested Book"
          sortBy="userBook.book.title"
        >
          <ReferenceField source="book" reference="books" link={false}>
            <TextField source="title" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          source="userBook"
          reference="user_books"
          label="Owner"
          sortBy="userBook.user.name"
        >
          <ReferenceField source="user" reference="users" >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          source="user"
          reference="users"
          label="User"
          sortBy="user.name"
        >
          <TextField source="name"/>
        </ReferenceField>
        <TextField source={"status"}/>
        <DateField source={"date"} showTime={true}/>
      </Datagrid>
    </List>
  );
};

export default RequestList;
